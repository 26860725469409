<template>
  <div class="banner mb-5" :style="cssVariables">
    <div class="container">
      <div class="row gx-xl-7 py-5 py-xl-10">
        <div class="col-xl-6 d-flex align-items-center justify-content-center mb-6 mb-xl-0">
          <div class="banner-intro d-flex flex-column">
            <h2 class="banner-title mb-5">{{ school.website_banner_title }}</h2>
            <div class="banner-text mb-5" v-dompurify-html="school.website_banner_text"></div>
            <div v-if="school.settings.appointments_calendar.is_enabled">
              <Button
                size="md"
                :userType="school.website_banner_text_color === '#000000' ? 'black' : 'white'"
                variant="filled"
                :isExternal="school.settings.appointments_calendar.calendar_type === 'EXTERNAL'"
                :to="
                  school.settings.appointments_calendar.calendar_type === 'EXTERNAL'
                    ? school.settings.appointments_calendar.calendar_url
                    : appointmentBookingMenuItem.fullSlug
                "
              >
                Book an Appointment
              </Button>
            </div>
          </div>
        </div>
        <div
          class="col-xl-6 d-none d-xl-flex align-items-center justify-content-center order-xl-first"
        >
          <template v-if="school.website_banner_video_type === 'careertools'">
            <div class="banner-video">
              <VideoPlayer
                :videoUrl="careertoolsVideoPath + '/video.mp4'"
                :thumbnailUrl="careertoolsVideoPath + '/poster.jpg'"
              />
            </div>
          </template>
          <template v-if="school.website_banner_video_type === 'youtube'">
            <VideoEmbeded
              :showLoadingBox="true"
              :url="`https://www.youtube.com/embed/${school.website_banner_video}`"
            />
          </template>
          <template v-if="school.website_banner_video_type === 'vimeo'">
            <VideoEmbeded
              :showLoadingBox="true"
              :url="`https://player.vimeo.com/video/${school.website_banner_video}`"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, useContext, useStore } from '@nuxtjs/composition-api';
import useSchool from '@/hooks/school';
import Button from '@/components/Button';
import VideoPlayer from '@/components/video/Player';
import VideoEmbeded from '@/components/video/Embeded';
import { findMenuItemByComponentName } from '@/utils/navMenu';

export default defineComponent({
  components: { Button, VideoPlayer, VideoEmbeded },
  setup() {
    const { $config } = useContext();
    const { school, navMenuItems } = useSchool();
    const store = useStore();

    const careertoolsVideoPath = computed(() => {
      if (school.value.website_banner_video_type !== 'careertools') {
        return null;
      }

      const video = school.value.website_banner_video ?? 'v135-meaningful-job';
      return `${$config.publicObjectStorageUrl}/banner/videos/${video}`;
    });

    const cssVariables = computed(() => ({
      '--banner-bg-image': `url(${$config.publicObjectStorageUrl}/${school.value.website_banner_background_image})`,
      '--banner-text-color': school.value.website_banner_text_color ?? '#ffffff',
    }));

    const appointmentBookingMenuItem = findMenuItemByComponentName(
      'appointment-booking',
      store.getters.schoolNavMenuItems,
    );

    return { cssVariables, school, careertoolsVideoPath, navMenuItems, appointmentBookingMenuItem };
  },
});
</script>

<style lang="scss" scoped>
.banner {
  background-image: var(--banner-bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  .banner-intro {
    max-width: 600px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .banner-title {
      color: var(--banner-text-color);
    }
    .banner-text {
      color: var(--banner-text-color);
      ::v-deep p {
        color: var(--banner-text-color);
      }
    }
  }
}
</style>
