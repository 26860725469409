<template>
  <div>
    <Banner />
    <Widgets />
  </div>
</template>

<script>
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import Banner from '@/components/schoolWebsite/Banner';
import Widgets from '@/components/schoolWebsite/widgets/Widgets';
import { PRICING_TIERS_ALL } from '@/constants/pricingTiers';
import usePageTitle from '@/hooks/pageTitle';

export default defineComponent({
  components: {
    Banner,
    Widgets,
  },
  layout: 'school-website',
  auth: false,
  middleware: ['website-enabled'],
  meta: {
    allowedPricingTiers: PRICING_TIERS_ALL,
  },
  setup() {
    const { setPageTitle } = usePageTitle();
    onMounted(() => {
      setPageTitle(null);
    });
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
