<template>
  <div class="container my-lg-7 my-3">
    <div class="row gx-3 mb-3">
      <div class="col-lg-4 mb-4">
        <Widget v-if="school.website_widgets.left" :type="school.website_widgets.left" />
      </div>
      <div class="col-lg-4 mb-4">
        <Widget v-if="school.website_widgets.center" :type="school.website_widgets.center" />
      </div>
      <div class="col-lg-4">
        <Widget v-if="school.website_widgets.right" :type="school.website_widgets.right" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Widget from '@/components/schoolWebsite/widgets/Widget';
import useSchool from '@/hooks/school';

export default defineComponent({
  components: { Widget },
  setup() {
    const { school } = useSchool();
    return { school };
  },
});
</script>

<style lang="scss" scoped></style>
