<template>
  <video :key="videoUrl" controls :poster="thumbnailUrl">
    <source :src="videoUrl" type="video/mp4" />
    Sorry, your browser doesn't support embedded videos.
  </video>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
video {
  width: 100%;
}
</style>
