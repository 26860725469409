<template>
  <div class="video-wrapper">
    <div class="loading" :class="showLoadingBox && 'loading-box'" v-show="loading">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <Loader class="loading-cog" :width="200" :height="200" />
      </div>
    </div>
    <iframe
      v-show="!loading"
      :src="url"
      title="YouTube video player"
      frameborder="0"
      :allow="allowOptions"
      allowfullscreen
      @load="loading = false"
    ></iframe>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import Loader from '@/components/loaders/Loader';

export default defineComponent({
  components: {
    Loader,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    showLoadingBox: {
      type: Boolean,
    },
  },
  setup() {
    const allowOptions =
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';

    const loading = ref(true);
    return { allowOptions, loading };
  },
});
</script>

<style lang="scss" scoped>
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
  width: 100%;
}

.loading-box {
  background-color: black;
  opacity: 0.8;
}

iframe,
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
