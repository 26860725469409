<template>
  <div>
    <template v-if="type !== 'quote-of-the-week'">
      <client-only>
        <div class="widget p-3">
          <component :is="widgetComponent" />
        </div>
      </client-only>
    </template>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const widgetComponentMappings = {
      'good-universities-guide-search': 'GoodUniversitiesGuideSearch',
      'good-universities-guide-link': 'GoodUniversitiesGuideLink',
      'atar-course-search': 'AtarCourseSearch',
      'career-target': 'CareerTarget',
      'combined-state-course-search': 'CombinedStateCourseSearch',
      'uac-course-search': 'UacCourseSearch',
      'vtac-course-search': 'VtacCourseSearch',
      'qtac-course-search': 'QtacCourseSearch',
      'tisk-course-search': 'TiskCourseSearch',
      'satac-search': 'SatacSearch',
      'compared-search': 'ComparedSearch',
      'message-board': 'MessageBoard',
      newsfeed: 'Newsfeed',
      'newsfeed-subscribe': 'NewsfeedSubscribe',
      'schools-careers-newsletter': 'SchoolsCareerNewsletter',
      'careertools-newsletter-subscribe': 'CareertoolsNewsletterSubscribe',
      facebook: 'Facebook',
      events: 'Events',
      'country-edu-foundation-link': 'CountryEducationFoundationLink',
    };

    const clientOnlyWidgets = ['facebook'];

    const widgetComponent = computed(
      () => () =>
        // eslint-disable-next-line prefer-template
        import('@/components/schoolWebsite/widgets/types/' + widgetComponentMappings[props.type]),
    );

    const isClientOnlyWidget = computed(() => clientOnlyWidgets.includes(props.type));

    return { widgetComponent, isClientOnlyWidget };
  },
});
</script>

<style lang="scss" scoped>
.widget {
  background: #f3f4f6;
  border: 1px solid #dadada;
  border-radius: 6px;
}
</style>
